<template>
  <section class="py-5 text-center container">
    <div class="row py-lg-5">
      <div class="col-lg-6 col-md-8 mx-auto">
        <h1 class="fw-bolder">IndieGameJobs</h1>
        <p class="lead text-muted">Connecting best Indie studios with best developers</p>
        <form class="d-flex">
          <input class="form-control me-2" type="search" placeholder="Search" aria-label="Search">
          <button class="btn btn-dark" type="submit">Search</button>
        </form>
      </div>
    </div>
  </section>
    <div v-if="jobs.length" class="container mx-auto mt-4">
      <div class="row">
      <div v-for="job in jobs" :key="job.jobId" class="col-md-4">
        <div class="">
          <div class="card" style="width: 18rem;">
            <img src="https://i.imgur.com/ZTkt4I5.jpg" class="card-img-top" alt="...">
            <div class="card-body">
              <h5 class="card-title">{{ job.title }}</h5>
              <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
              <p class="card-text">{{ job.description }}</p>
              <router-link :to="{name: 'JobDetails', params: { id: job.jobId }}" class="btn btn-secondary mr-2">
                <i class="fas fa-link"></i>Job Details
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
</template>

<script>
import ApiServices from '../services/ApiServices.js'
export default {
  data() {
    return {
      jobs: [],
    }
  },
  created() {
    ApiServices.getAllJobs()
      .then(jobs => {
        console.log(jobs)
        console.log(JSON.stringify(jobs))
        this.jobs = jobs.data
      })
      .catch(err => console.log(err.message))      
  }
}
</script>

<style scoped>
/* :root {
  --gradient: linear-gradient(to left top, #DD2476 10%, #FF512F 90%) !important;
}

body {
  background: #111 !important;
}

.card {
  background: #222;
  border: 1px solid #dd2476;
  color: rgba(250, 250, 250, 0.8);
  margin-bottom: 2rem;
}

.btn {
  border: 5px solid;
  border-image-slice: 1;
  background: var(--gradient) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
  border-image-source:  var(--gradient) !important; 
  text-decoration: none;
  transition: all .4s ease;
}

.btn:hover, .btn:focus {
  background: var(--gradient) !important;
  -webkit-background-clip: none !important;
  -webkit-text-fill-color: #fff !important;
  border: 5px solid #fff !important; 
  box-shadow: #222 1px 0 10px;
  /* text-decoration: underline; 
} */
</style>