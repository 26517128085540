
<template>
  <h1>Jobs</h1>
  <div v-if="jobs.length">
    <div v-for="job in jobs" :key="job.jobId" class="job">
      <router-link :to="{name: 'JobDetails', params: { id: job.jobId }}">
        <h2>{{ job.title }}</h2>
      </router-link>
    </div>
  </div>
  <div v-else>
    <p>Loading jobs...</p>
  </div>
</template>

<script>
import ApiServices from '../../services/ApiServices.js'
export default {
  data() {
    return {
      jobs: [],
    }
  },
  created() {
    ApiServices.getAllJobs()
      .then(jobs => {
        console.log(jobs)
        console.log(JSON.stringify(jobs))
        this.jobs = jobs.data
      })
      .catch(err => console.log(err.message))      
  }
}
</script>

<style>
  .job h2{
    background: #f4f4f4;
    padding: 20px;
    border-radius: 10px;
    margin: 10px auto;
    max-width: 600px;
    cursor: pointer;
    color: #444;
  }
  .job h2:hover {
    background: #ddd;
  }
  .job a{
    text-decoration: none;
  }
</style>