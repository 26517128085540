<template>
  <form @submit.prevent="handleSubmit">
    <label>Company Name</label>
    <input type="companyName" v-model="company" required>

    <label>Title:</label>
    <input type="text" v-model="title" required>

    <label>Location:</label>
    <input type="text" v-model="location" required>

    <label>Salary in USD:</label>
    <input type='number' v-model="salary" required/>

    <label>Summary:</label>
    <input type="text" v-model="summary" required>
    
    <label>Description:</label>
    <textarea v-model="description" required>
    </textarea>

    <label>Requirements:</label>
    <input type="text" v-model="requirements" required>

    <label>jobType:</label>
    <select v-model="jobType">
      <option value="Full-Time">Full-Time</option>
      <option value="Part-Time">Part-Time</option>
       <option value="Contract">Contract</option>
       <option value="Internship">Internship</option>
    </select>

    <label>Experience:</label>
    <select v-model="experience">
      <option value="no-exp">no-experience</option>
      <option value="junior">junior(1-2)yrs</option>
       <option value="mid">mid-level(2-5)yrs</option>
       <option value="senior">senior(5+)yrs</option>
    </select>

    <label>Packages:</label>
    <select v-model="packages">
      <option value="BASIC">Basic</option>
      <option value="PRO">Pro</option>
       <option value="ULTIMATE">Ultimate</option>
    </select>

    <div class="submit">
      <button>Submit job post</button>
    </div>
  </form>
</template>

<script>
import ApiServices from '../../services/ApiServices.js'
export default {
    data() {
        return {
            company: '',
            title: '',
            location: '',
            experience: '',
            jobType: '',
            salary: '2000',
            summary: '',
            description: '',
            requirements: '',
            packages: [],
        }
    },
    methods: {
        handleSubmit() {
            var jobobject = {};
            const job = {
                company: this.company,
                title: this.title,
                location: this.location,
                salary: this.salary,
                jobType: this.jobType,
                experience: this.experience,
                summary: this.summary,
                description: this.description,
                requirements: this.requirements,
                "logo": "https://logo.url/here",
                "tags": ["game design", "junior", "Progressimo"],
                "category": "GAME_DESIGNER"
            }
            jobobject['jobPosting'] = job;
            jobobject['packageDetails'] = {'type': this.packages, "addons":["REPORTING"]};
            console.log(JSON.stringify(jobobject));
            ApiServices.setToken(localStorage.getItem('token'));
            ApiServices.CreateJob(jobobject)
            .then(response => {
                console.log(response.data)
                window.location.href = response.data.checkoutUrl;
            })
            .catch(error => {
                console.log(error)
            })
        }
        }
}
</script>

<style>
  form {
    max-width: 420px;
    margin: 30px auto;
    background: white;
    text-align: left;
    padding: 40px;
    border-radius: 10px;
  }
  label {
    color: #aaa;
    display: inline-block;
    margin: 25px 0 15px;
    font-size: 0.6em;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: bold;
  }
  input, select {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
  }
  input[type="checkbox"] {
    display: inline-block;
    width: 16px;
    margin: 0 10px 0 0;
    position: relative;
    top: 2px;
  }
  .pill {
    display: inline-block;
    margin: 20px 10px 0 0;
    padding: 6px 12px;
    background: #eee;
    border-radius: 20px;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #777;
    cursor: pointer;
  }
  button {
    background: #0b6dff;
    border: 0;
    padding: 10px 20px;
    margin-top: 20px;
    color: white;
    border-radius: 20px;
  }
  .submit {
    text-align: center;
  }
  .error {
    color: #ff0062;
    margin-top: 10px;
    font-size: 0.8em;
    font-weight: bold;
  }
  textarea {
    display: block;
    padding: 10px 6px;
    width: 100%;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ddd;
    color: #555;
    height: 200px;
  }
</style>