
<template>
  <div v-if="job" class="container">
    <h1 class="my-4">{{ job.title }} -
      <small>{{ job.company }}</small>
    </h1>
     <div class="row">
  
      <div class="col-md-8">
        <img class="img-fluid" src="https://via.placeholder.com/750x500" alt="">
      </div>
  
      <div class="col-md-4">
        <h3 class="my-3">Description</h3>
        <p>{{ job.description }}</p>
        <h3 class="my-3">Job Details</h3>
        <ul>
          <li class="salary">{{ job.salary }}</li>
          <li>Location : {{ job.location }}</li>
          <li>{{ job.jobType }}</li>
          <li>{{ job.experience }}</li>
        </ul>
      </div>
  
    </div>
    <!-- /.row -->
  
    <!-- Related Projects Row -->
    <h3 class="my-4">Related Projects</h3>
  
    <div class="row">
  
      <div class="col-md-3 col-sm-6 mb-4">
        <a href="#">
              <img class="img-fluid" src="https://via.placeholder.com/500x300" alt="">
            </a>
      </div>
  
      <div class="col-md-3 col-sm-6 mb-4">
        <a href="#">
              <img class="img-fluid" src="https://via.placeholder.com/500x300" alt="">
            </a>
      </div>
  
      <div class="col-md-3 col-sm-6 mb-4">
        <a href="#">
              <img class="img-fluid" src="https://via.placeholder.com/500x300" alt="">
            </a>
      </div>
  
      <div class="col-md-3 col-sm-6 mb-4">
        <a href="#">
              <img class="img-fluid" src="https://via.placeholder.com/500x300" alt="">
            </a>
      </div>
  
    </div>
    <!-- /.row -->
  
  </div>
  <!-- /.container -->
  <div v-else>
    <p>Loading job details...</p>
  </div>
</template>

<script>
import ApiServices from '../../services/ApiServices.js'
export default {
  props: ['id'],
  data() {
    return {
      job: null
    }
  },
  mounted() {
    ApiServices.getJobDetails(this.id)
      .then(job => {
        this.job = job.data
      })
      .catch(err => console.log(err.message))
  }
}
</script>

<style>
.company {
  font-weight: bold;
}
.description {
  margin-top: 1rem;
}
.salary {
  margin-top: 1rem;
  font-weight: bold;
  color: rgb(53, 246, 53);
}
button {
  background: #00bcd4;
  color: #fff;
  border: none;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
}
</style>