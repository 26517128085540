<template>
    <!-- navbar -->
  <nav class="navbar navbar-expand-md navbar-light pt-5 pb-4">
    <div class="container-xxl">
      <!-- navbar brand / title -->
      <router-link to="/" :key="$route.fullPath" class="navbar-brand">
        <span class="text-secondary fw-bold">
          IndieGameJobs
        </span>
      </router-link>
      <!-- toggle button for mobile nav -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- if logged in --><!-- navbar links -->
      <div v-if="localstorage.getItem('token')">
        <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" :key="$route.fullPath" class="nav-link">
              <span class="text-light">Home</span>
              </router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'About' }" class="nav-link">About</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'JobCreate' }" class="nav-link">JobCreate</router-link>
            </li>
            <li class="nav-item">
              <router-link :to="{ name: 'Logout' }" class="nav-link">Logout</router-link>
            </li>
          </ul>
        </div>
      </div>
      <!-- if not logged in --><!-- navbar links -->
      <div v-else>
        <div class="collapse navbar-collapse justify-content-end align-center" id="main-nav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link :to="{ name: 'About' }" class="nav-link">About</router-link>
            </li>
            <li class="nav-item d-md-none">
              <a class="nav-link" href="#pricing">Pricing</a>
            </li>
            <li class="nav-item ms-2 d-none d-md-inline">
              <router-link :to="{ name: 'Signup' }" :key="$route.fullPath" class="btn btn-dark">Signup</router-link>
            </li>
            <li class="nav-item ms-2 d-none d-md-inline">
              <router-link :to="{ name: 'Login' }" class="btn btn-secondary">Login</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
  <router-view/>
</template>
<script>
export default {
  data() {
    return {
      localstorage: window.localStorage
    }
  },
}
</script>
<style>

/********************** */


</style>
