<template>
  <div>
    <h1>Logout</h1>
    <p>You are now logged out.</p>

  </div>
</template>

<script>
export default {
    created() {
        localStorage.clear();
        this.$router.push('/')
    }
}
</script>


<style>

</style>