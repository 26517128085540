<template>
  <form @submit.prevent="handleSubmit">
    <label>Email:</label>
    <input type="email" v-model="email" required>

    <label>Password:</label>
    <input type="password" v-model="password" required>
    <div v-if="passwordError" class="error">{{ passwordError }}</div>

    <label>Role:</label>
    <select v-model="role">
      <option value="COMPANY">Company</option>
      <option value="USER">User</option>
    </select>

    <div class="submit">
      <button>Login</button>
    </div>
  </form>
</template>

<script>
import ApiServices from '../../services/ApiServices.js'
export default {
  data() {
    return {
      email: '',
      password: '',
      passwordError: null,
      role: 'COMPANY'
    }
  },
  methods: {
    handleSubmit() {
      var credentials = {};
      credentials["email"] = this.email;
      credentials["password"] = this.password;
      credentials["accountType"] = this.role;
      ApiServices.login(credentials)
          .then(response => {
            console.log('response: ', response.data)
            console.log("data : ", JSON.stringify(response.data))
            localStorage.setItem('token', response.data.xAccessToken);
            localStorage.setItem('accountId', response.data.accessTokenData["accountId"])
            localStorage.setItem('accountType', response.data.accessTokenData["accountType"])
            localStorage.setItem('validUntil', response.data.accessTokenData["validUntil"])
            console.log("token : ", localStorage.getItem('token'))
            console.log("accountId : ", localStorage.getItem('accountId'))

            this.$router.push('/')
          })
          .catch(error => {
            console.log('error: ', error)
          })
      }
    }
}

</script>

<style>

</style>