<template>
  <h1>Stripe payment success</h1>
  <button @click="ReturnToHome">Return to Home</button>
</template>

<script>
export default {
    name: 'Success',
    methods: {
        ReturnToHome() {
        this.$router.push('/')
        }
    }
}
</script>

<style>

</style>