<template>
  <h2>404</h2>
  <h3>That page does not exist</h3>
</template>

<script>
export default {
}
</script>

<style>
</style>