import axios from 'axios';

const apiClient = axios.create({
    baseURL: 'https://indiegamejobs-production.up.railway.app/',
    withCredentials: false,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
    }
});

export default {
    getAllJobs() {
        return apiClient.get('/job/all');
    },
    getJobDetails(id) {
        return apiClient.get('/job/' + id);
    },
    createCompany(company) {
        return apiClient.post('/account/create/company', company);
    },
    login(credentials) {
        return apiClient.post('/auth/login', credentials);
    },
    CreateJob(job) {
        return apiClient.post('/job/create', job);
    },
    setToken(token) {
        apiClient.defaults.headers.common['x-access-token'] = token;
    }
};